import React, { useState, useContext, useEffect } from "react";
import { FaChartBar, FaMoneyBillWave, FaChartLine } from "react-icons/fa";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { WalletContext } from "../WalletContext";
import LoanHealthGauge from "../LoanHealthGauge";
import AllLoans from "./AllLoans";
import ManageLoan from "./ManageLoan";
import { CollateralsContext } from "../CollateralsContext"; // Import CollateralsContext

// Provide default values if selectedLoan is null
const defaultLoan = {
  id: "default-loan-id",
  healthFactor: 0,
  borrowedAmount: 0,
  collateralDeposit: 0,
  collateralName: "",
  collateralPrice: 0,
  accumulatedFee: 0,
  totalRepay: 0,
  healthStatus: "Unknown",
  liquidationDate: "N/A",
  liquidationFee: 0,
  liquidationPrice: 0,
};

const MAX_COLLATERAL_DEPOSIT = 2000; // Max collateral deposit in USD
const accumulatedFee = 1; // Accumulated fee in USD

function Loans() {
  const {
    walletConnected,
    tokens,
    loans,
    columns,
    setLoans,
    setTokens,
    setOpenPositions,
  } = useContext(WalletContext);
  const { collateralName } = useParams(); // Get collateralName from URL params
  const location = useLocation(); // Access location to get state
  const { collaterals, setCollaterals } = useContext(CollateralsContext); // Use collaterals from context
  const [selectedLoan, setSelectedLoan] = useState(
    location.state?.selectedLoan || null
  );
  const [contractAddress, setContractAddress] = useState(null); // State to store contract address
  const [showManageLoan, setShowManageLoan] = useState(false); // State to track navigation
  const [activeContainer, setActiveContainer] = useState("Deposit");
  const [MAX_BORROW, setMaxBorrow] = useState(0); // Max borrow amount in USD
  const [BorrowLimit, setBorrowLimit] = useState(0); // Max borrow amount in USD
  const [RepayButtonClicked, setRepayButtonClicked] = useState(false);
  const [BorrowButtonClicked, setBorrowButtonClicked] = useState(false);
  const navigate = useNavigate();

  const loanToUse = selectedLoan || defaultLoan;

  const handleReceiveLoan = () => {
    setShowLoanMessage(true);
  };

  const handleRepayClick = () => {
    //setSelectedLoan(null);
    setRepayButtonClicked(true);
    setBorrowButtonClicked(false);
    setShowManageLoan(true);
  };

  const handleBorrowClick = () => {
    setRepayButtonClicked(false);
    setBorrowButtonClicked(true);
    setSelectedLoan(null);
    setShowManageLoan(false);
  };

  const handleRowClick = (loan) => {
    setSelectedLoan(loan);
    setShowManageLoan(true);
  };
  const filteredLoans = loans.filter(
    (loan) => loan.contract === collateralName
  );

  const selectedColumns = columns.filter((column) =>
    [
      "contract",
      "collateralName",
      "collateralDeposit",
      "borrowedAmount",
      "dailyFee",
      "accumulatedFee",
      "totalRepay",
      "remainingDuration",
      "healthStatus",
      "healthFactor",
      "liquidationdate",
      "liquidationPrice",
    ].includes(column.key)
  );

  const [selectedCollateral, setSelectedCollateral] = useState(collateralName);
  const collateral = collaterals.find(
    (token) => token.name === selectedCollateral
  );

  const [borrowAmount, setBorrowAmount] = useState(0);
  const [duration, setDuration] = useState(1);
  const [newLoan, setNewLoan] = useState(null);
  const [isCollateralDeposited, setIsCollateralDeposited] = useState(false);
  const [isLoanReceived, setIsLoanReceived] = useState(false);
  const [deposited, setDeposited] = useState(0);
  const [showDepositMessage, setShowDepositMessage] = useState(false);
  const [showLoanMessage, setShowLoanMessage] = useState(false);
  const [collateralDeposit, setCollateralDeposit] = useState(0);
  const [ltv, setLtv] = useState(0);
  const liquidationFee = borrowAmount / 300; // 3% of the borrowed amount
  const [dailyfee, setDailyFee] = useState(1);
  const totalAdminFee = duration * dailyfee;
  const totalRepay = borrowAmount + accumulatedFee;
  const [healthFactor, setHealthFactor] = useState(0);

  useEffect(() => {
    if (collateralName) {
      setSelectedCollateral(collateralName);
    }
  }, [collateralName]);

  const handleCollateralChange = (event) => {
    const selected = event.target.value;
    setSelectedCollateral(selected);
    navigate(`/loans/${selected}`);
  };

  const calculateDailyFee = (value) => {
    const dailyfee = value;
    if (dailyfee <= 1000) {
      setDailyFee(0.5);
    } else if (dailyfee > 1000 && dailyfee <= 1500) {
      setDailyFee(0.75);
    } else if (dailyfee > 1500 && dailyfee <= 2000) {
      setDailyFee(1);
    } else if (dailyfee > 2000) {
      setDailyFee(1.5);
    }
  };

  if (!collateral) {
    return <div>Collateral not found</div>;
  }

  const handleBorrowAmountChange = (e) => {
    const value = e.target.value;
    const numericValue = Math.round(value);

    if (numericValue > MAX_BORROW) {
      alert(`You can't borrow more than ${MAX_BORROW} USD`);
    } else if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
      // Allow only numbers and decimal points
      setBorrowAmount(numericValue);
      calculateDailyFee(numericValue);
      calculateHealthFactor(numericValue);
      // Calculate and update LTV
      const collateralValue = collateral.price * collateralDeposit;
      const newLtv = (numericValue / collateralValue) * 100;
      setLtv(newLtv);
    }
  };

  const handleDurationChange = (e) => {
    const value = Math.round(e.target.value);
    setDuration(value);
    calculateLiquidationDate();
    calculateLiquidationFee();
    calculateHealthFactor(borrowAmount);
  };

  const handleCollateralDepositChange = (e) => {
    const value = parseFloat(e.target.value);
    // Allow only numbers and decimal points
    if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
      setCollateralDeposit(value);
      calculateMaxBorrow(value);
    }
  };

  const handleLtvChange = (e) => {
    const value = Math.min(e.target.value, collateral.maxltv);

    setLtv(value);
    const newBorrowAmount = Math.round(
      (value / 100) * collateralDeposit * collateral.price
    );
    setBorrowAmount(newBorrowAmount);
    calculateDailyFee(newBorrowAmount);
    calculateHealthFactor(newBorrowAmount);
  };

  const calculateRequiredCollateral = () => {
    const maxLTV = collateral.maxltv / 100; // Convert percentage to decimal
    return borrowAmount / maxLTV / collateral.price;
  };

  const calculateLoanAmount = () => {
    return borrowAmount;
  };

  const calculateAdminFee = () => {
    const totalAdminFee = duration * dailyfee;
    return totalAdminFee;
  };

  const calculateTotalRepay = () => {
    const totalRepay = borrowAmount + dailyfee;
    return totalRepay;
  };

  const generateRandomString = (length) => {
    const characters = "abcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleDeposit = () => {
    if (!walletConnected) {
      alert("Please connect your wallet first.");
      return;
    }
    setShowDepositMessage(true);
    const updatedTokens = tokens.map((token) => {
      if (token.name === collateral.name) {
        return { ...token, quantity: token.quantity - collateralDeposit };
      }
      return token;
    });
    setTokens(updatedTokens);
    setIsCollateralDeposited(true);

    const updatedCollaterals = collaterals.map((collateral) => {
      if (collateral.name === collateralName) {
        return {
          ...collateral,
          totallocked: collateral.totallocked + collateralDeposit,
        };
      }
      return collateral;
    });
    setCollaterals(updatedCollaterals);
  };

  const usrwallet =
    "0xusr..." + Math.floor(10 + Math.random() * 90) + generateRandomString(3);

  const handleOkClick = () => {
    setShowDepositMessage(false);
    setBorrowLimit(MAX_BORROW);
    setIsCollateralDeposited(true);
    setDeposited(collateralDeposit);
    calculateHealthFactor();
  };

  const getLtvColor = (ltv) => {
    if (ltv <= 66) {
      return "green";
    } else if (ltv <= 95) {
      return "yellow";
    } else {
      return "red";
    }
  };

  const handleLoanOkClick = () => {
    const contract =
      "0xebm..." +
      Math.floor(10 + Math.random() * 10) +
      generateRandomString(3);
    setContractAddress(contract);
    const newLoan = {
      active: true,
      user: usrwallet,
      contract: contract,
      collateralName: collateral.name,
      collateralDeposit: collateralDeposit,
      collateralValue: deposited * collateral.price,
      borrowedToken: "USDS",
      borrowedAmount: borrowAmount,
      contractDuration: duration,
      remainingDuration: duration,
      dailyFee: dailyfee,
      accumulatedFee: 0,
      totalRepay: borrowAmount,
      healthFactor: healthFactor,
      healthStatus: getHealthStatus(),
      liquidationdate: calculateLiquidationDate(),
      liquidationPrice: calculateLiquidationPrice(),
      liquidationFee: calculateLiquidationFee(),
      liquidatorAccess: "No",
      liquidated: "No",
    };

    const updatedTokens = tokens.map((token) => {
      if (token.name === "USDS") {
        return { ...token, quantity: token.quantity + borrowAmount };
      }
      return token;
    });
    setTokens(updatedTokens);

    const updatedCollaterals = collaterals.map((collateral) => {
      if (collateral.name === collateralName) {
        return {
          ...collateral,
          totalborrowedUSDS: collateral.totalborrowedUSDS + borrowAmount,
        };
      }
      return collateral;
    });

    setCollaterals(updatedCollaterals);
    setShowLoanMessage(false);
    setIsLoanReceived(true);
    setIsCollateralDeposited(true);
    setLoans([...loans, newLoan]);
    setOpenPositions([...loans, newLoan]);
    setNewLoan(newLoan);
    setBorrowAmount(0);
    // Reset the containers
    setBorrowAmount(0);
    setDuration(1);
    setIsCollateralDeposited(false);
    setCollateralDeposit(0);
    setLtv(0);
    setSelectedLoan(newLoan);
  };

  // Calculate the liquidation eligible date
  const calculateLiquidationDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + parseInt(duration) + 3);
    return currentDate.toDateString();
  };

  // Calculate the liquidation fee then liquidation Threshold
  const calculateLiquidationFee = () => {
    const liquidationFee = collateralDeposit * 0.1; // 1% of the colateral deposited
    return liquidationFee;
  };

  const calculateLiquidationPrice = () => {
    const liquidationPrice = (totalRepay + 5) / (collateralDeposit * 0.8);
    return parseFloat(liquidationPrice).toFixed(6);
  };

  // Calculate the health factor of the loan using the formula
  const calculateHealthFactor = (newBorrowAmount) => {
    const healthFactor =
      (collateralDeposit * collateral.price) / newBorrowAmount;

    setHealthFactor(parseFloat(healthFactor.toFixed(2)));
  };

  const calculateMaxBorrow = (collateralDeposit) => {
    const value = (
      (collateralDeposit * collateral.price * collateral.maxltv) /
      100
    ).toFixed(2);
    setMaxBorrow(value);
  };

  const getHealthStatus = () => {
    const value = healthFactor;
    if (value > 1.5) return "Good";
    if (value >= 1.3 && value <= 1.49) return "Moderate";
    if (value >= 1.1 && value <= 1.29) return "Risky";
    return "Liq. eligible";
  };

  const renderContainer = (container) => {
    switch (container) {
      case "Deposit":
        return (
          <div
            id="Deposit"
            className="tw-flex tw-flex-col tw-items-center tw-p-6 tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-text-white tw-bg-black tw-h-full tw-shadow-lg tw-mt-0"
          >
            <h3 className="tw-text-2xl tw-mb-6 tw-font-poppins tw-text-[#2DB0F3]">
              Deposit Collateral
            </h3>

            {walletConnected && (
              <div className="tw-w-full tw-mb-4 tw-flex tw-justify-between">
                <p className="tw-text-lg">Available in Wallet:</p>
                <p className="tw-text-base tw-font-semibold">
                  {collateral.quantity} {collateral.name}
                </p>
              </div>
            )}
            <div className="tw-w-full tw-mb-4 tw-flex tw-justify-between">
              <p className="tw-text-lg">Suggested :</p>
              <p className="tw-text-base tw-font-semibold">
                {MAX_COLLATERAL_DEPOSIT / collateral.price}
              </p>
            </div>
            <label className="tw-w-full tw-mb-4">
              <p className="tw-text-lg tw-text-[#2DB0F3]">
                Deposit Collateral:
              </p>
              <input
                type="number"
                value={collateralDeposit}
                onChange={handleCollateralDepositChange}
                className="tw-w-full tw-p-2 tw-mb-4 tw-border tw-border-[#2DB0F3] tw-rounded tw-text-black tw-bg-white"
                step="0.001"
                min={0}
                disabled={isCollateralDeposited || isLoanReceived}
              />
            </label>
            <div className="tw-w-full tw-mb-4 tw-flex tw-justify-between">
              <p className="tw-text-lg">Collateral Value:</p>
              <p className="tw-text-base tw-font-semibold">
                {collateralDeposit * collateral.price} USD
              </p>
            </div>
            <div className="tw-w-full tw-mb-4 tw-flex tw-justify-between">
              <p className="tw-text-lg">Max Borrow USDS:</p>
              <p className="tw-text-base tw-font-semibold">{MAX_BORROW} USD</p>
            </div>
            <button
              onClick={handleDeposit}
              className={`tw-px-4 tw-py-2 tw-mb-2 tw-rounded tw-text-white tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb] ${
                isCollateralDeposited || collateralDeposit === 0
                  ? "tw-opacity-50 tw-cursor-not-allowed"
                  : ""
              }`}
              disabled={isCollateralDeposited || collateralDeposit === 0}
            >
              Approve Collateral
            </button>
            {showDepositMessage && (
              <div className="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-75 tw-z-50">
                <div className="tw-bg-black tw-p-4 tw-rounded tw-border tw-border-[#2DB0F3] tw-rounded-lg">
                  <p className="tw-mb-2 tw-text-lg tw-text-white">
                    Collateral Approved for deposit into the contract.
                  </p>
                  <button
                    onClick={handleOkClick}
                    className="tw-mt-2 tw-px-4 tw-py-2 tw-rounded tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb] tw-text-white"
                  >
                    OK
                  </button>
                </div>
              </div>
            )}

            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between tw-mt-4">
              <p className="tw-text-lg">Collateral Deposited:</p>
              <p className="tw-text-base tw-font-semibold">
                {deposited} {collateral.name}
              </p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between ">
              <p className="tw-text-lg">Collateral Value:</p>
              <p className="tw-text-base tw-font-semibold">
                {deposited * collateral.price} USD
              </p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between ">
              <p className="tw-text-lg">Borrow Limit:</p>
              <p className="tw-text-base tw-font-semibold">{BorrowLimit} USD</p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between ">
              <p className="tw-text-lg">Loan Contract:</p>
              <p className="tw-text-base tw-font-semibold">{contractAddress}</p>
            </div>
          </div>
        );
      case "Borrow":
        return (
          <div
            id="Borrow"
            className="tw-flex tw-flex-col tw-items-center tw-p-6 tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-text-white tw-bg-black tw-h-full tw-shadow-lg"
          >
            <h3 className="tw-text-2xl tw-mb-4  tw-font-poppins tw-text-[#2DB0F3]">
              Borrow
            </h3>
            <div className="tw-w-full tw-flex tw-justify-between ">
              <p className="gradient">Loan Duration (7-30):</p>
              <label className=" tw-items-center tw-full tw-flex tw-text-lg tw-text-[#2DB0F3]">
                <input
                  type="number"
                  value={duration}
                  onChange={handleDurationChange}
                  min="7"
                  max="30"
                  className=" tw-p-1 tw-mb-2 tw-ml-16 tw-border tw-border-[#2DB0F3] tw-rounded tw-text-black tw-bg-white"
                />
              </label>
            </div>
            <div className="tw-w-full tw-flex tw-justify-between ">
              <LoanHealthGauge value={healthFactor} />
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between tw-h-auto">
              <label className="tw-block tw-mb-2 tw-text-lg tw-text-[#2DB0F3] tw-mt-4 tw-w-full">
                LTV:
                <div className="tw-relative tw-w-full tw-mb-4">
                  <input
                    type="range"
                    value={ltv}
                    onChange={handleLtvChange}
                    min="0"
                    max={collateral.maxltv}
                    className="tw-w-full tw-mb-4"
                    style={{ zIndex: 1, position: "relative" }}
                  />
                  <div
                    className="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-bg-yellow-500"
                    style={{
                      width: `100%`,
                      zIndex: 0,
                      opacity: 0.5,
                    }}
                  ></div>
                  <div
                    className="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-bg-green-500"
                    style={{
                      width: `${(ltv / 100) * 100}%`,
                      zIndex: 2,
                      opacity: 0.5,
                    }}
                  ></div>
                </div>
                <p className="tw-text-white">{ltv}%</p>
              </label>
            </div>

            <div className="tw-w-full tw-mb-4 tw-flex tw-justify-between">
              <label className="tw-flex tw-items-center tw-text-lg tw-text-[#2DB0F3]">
                <span className="tw-mr-3">Borrow:</span>
                <input
                  type="number"
                  value={borrowAmount}
                  onChange={(e) => handleBorrowAmountChange(e)}
                  className="tw-p-1 tw-mr-2 tw-w-auto tw-border tw-border-[#2DB0F3] tw-rounded tw-text-black tw-bg-white"
                  step="1"
                  min="0"
                  max={MAX_BORROW}
                />
              </label>
              <p className="tw-ml-0">USDS (DAI)</p>
            </div>
            <div className="tw-w-full tw-mb-4 tw-flex tw-justify-between">
              <p className="tw-text-lg">Daily Fee:</p>
              <p className="tw-text-xl tw-font-semibold">{dailyfee} $ </p>
            </div>
            <button
              onClick={handleReceiveLoan}
              className={`tw-px-4 tw-py-2 tw-rounded tw-text-white tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb] ${
                !isCollateralDeposited || borrowAmount === 0
                  ? "tw-opacity-50 tw-cursor-not-allowed"
                  : ""
              }`}
              disabled={!isCollateralDeposited || borrowAmount === 0}
            >
              Receive Loan Amount
            </button>
            {showLoanMessage && (
              <div className="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-75 tw-z-50">
                <div className="tw-bg-black tw-p-4 tw-rounded tw-border tw-border-[#2DB0F3] tw-rounded-lg">
                  <p className="tw-mb-2 tw-text-lg tw-text-white">
                    Collateral deposited and Amount transferred to the borrower
                  </p>
                  <button
                    onClick={() => handleLoanOkClick(newLoan)}
                    className="tw-mt-2 tw-px-4 tw-py-2 tw-rounded tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb] tw-text-white"
                  >
                    OK
                  </button>
                </div>
              </div>
            )}
          </div>
        );
      case "LoanStats":
        return (
          <div
            id="LoanStats"
            className="tw-flex tw-flex-col tw-items-center tw-p-6 tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-text-white tw-bg-black tw-h-full tw-shadow-lg"
          >
            <h3 className="tw-text-2xl tw-mb-6 tw-font-poppins tw-text-[#2DB0F3]">
              Loan Stats
            </h3>
            <div className="tw-w-full tw-mb-4 tw-flex tw-justify-between">
              <p className="tw-text-lg">Collateral Amount:</p>
              <p className="tw-text-base tw-font-semibold">
                {collateralDeposit || deposited}
              </p>
            </div>
            <div className="tw-w-full tw-mb-4 tw-flex tw-justify-between">
              <p className="tw-text-lg">Collateral Value:</p>
              <p className="tw-text-base tw-font-semibold">
                {collateralDeposit * collateral.price}
              </p>
            </div>
            <div className="tw-w-full tw-mb-4 tw-flex tw-justify-between">
              <p className="tw-text-lg">Loan Amount:</p>
              <p className="tw-text-base tw-font-semibold">
                {calculateLoanAmount()}
              </p>
            </div>
            <div className="tw-w-full tw-mb-4 tw-flex tw-justify-between">
              <p className="tw-text-lg">Loan Duration:</p>
              <p className="tw-text-xl tw-font-semibold">{duration} days</p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              <p className="tw-text-lg">Daily Admin Fee:</p>
              <p className="tw-text-xl tw-font-semibold">{dailyfee} $</p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              <p className="tw-text-lg">Total Repay next day:</p>
              <p className="tw-text-base tw-font-semibold">
                {calculateTotalRepay()} $
              </p>
            </div>
            <div className="tw-w-full tw-mb-2  tw-flex tw-justify-between">
              <p className="tw-text-lg">Health Factor:</p>
              <p className="tw-text-base tw-font-semibold">{healthFactor}</p>
            </div>
            <div className="tw-w-full tw-mb-2  tw-flex tw-justify-between">
              <p className="tw-text-lg">Health Status:</p>
              <p className="tw-text-base tw-font-semibold">
                {getHealthStatus()}
              </p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              <p className="tw-text-lg">Liq. Eligible Date:</p>
              <p className="tw-text-base tw-font-semibold">
                {calculateLiquidationDate()}
              </p>
            </div>
            <div className="tw-w-full tw-mb-2  tw-flex tw-justify-between">
              <p className="tw-text-lg">Liq. Price:</p>
              <p className="tw-text-base tw-font-semibold">
                {calculateLiquidationPrice()}
              </p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              <p className="tw-text-lg">Liquidator % {collateralName}:</p>
              <p className="tw-text-base tw-font-semibold">
                {calculateLiquidationFee()}
              </p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              <p className="tw-text-lg">Liq.Fee value:</p>
              <p className="tw-text-base tw-font-semibold">
                {calculateLiquidationFee() * collateral.price} USD
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="tw-bg-Hero tw-bg-cover tw-relative tw-bg-black tw-bg-center tw-w-full tw-h-auto">
      <div className="tw-absolute tw-top-0 tw-left-0">
        <img
          src={"https://i.postimg.cc/d3bMzs0h/left-image.png"}
          alt="Aqua color lighting from the left"
        />
      </div>
      <div className="tw-absolute tw-top-0 tw-right-0">
        <img
          src={"https://i.postimg.cc/8kMV6vXW/right-image.png"}
          alt="Aqua color lighting from the right"
        />
      </div>

      <div className="container tw-relative tw-pt-0 tw-pb-28 tw-px-4 md:tw-px-16 lg:tw-px-32 tw-mt-0 tw-mb-0">
        <div className="tw-block tw-items-center tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-0">
          <div className="tw-block tw-text-center tw-mt-0 ">
            <h1 className="gradient-text tw-font-semibold md:tw-text-xl tw-mb-4 tw-mt-0">
              Manage Loans
            </h1>
            <div className="tw-items-center tw-flex tw-justify-center tw-h-full tw-w-full tw-mb-4">
              <label
                htmlFor="collateral-select"
                className="tw-text-white tw-leading-7 tw-pt-0 tw-mb-0 tw-text-xl"
              >
                Collateral:
              </label>
              <select
                id="collateral-select"
                value={selectedCollateral}
                onChange={handleCollateralChange}
                className="tw-text-black tw-leading-7 tw-pt-0 tw-mb-0 tw-text-xl"
                disabled={selectedLoan}
              >
                {tokens.map((token) => (
                  <option key={token.name} value={token.name}>
                    {token.name}
                  </option>
                ))}
              </select>
              <img
                src={collateral.logo}
                alt="Collateral Logo"
                className="tw-w-5 tw-mr-2"
              />
              <span className="tw-text-white tw-leading-7 tw-pt-0 tw-mb-0 tw-text-xl">
                Current Price: ${collateral.price}
              </span>
            </div>
          </div>
        </div>
        <div className="tw-flex tw-justify-start tw-mb-4">
          <button
            onClick={handleBorrowClick}
            className={`tw-px-4 tw-py-2 tw-mr-2 tw-rounded tw-text-white ${
              BorrowButtonClicked
                ? "tw-bg-green-500"
                : "tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb]"
            }`}
          >
            Borrow
          </button>
          {/* REpay buttton to navigate to ManageLoan with no loan selected */}
          <button
            onClick={handleRepayClick}
            className={`tw-px-4 tw-py-2 tw-mr-2 tw-rounded tw-text-white ${
              RepayButtonClicked
                ? "tw-bg-green-500"
                : "tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb]"
            }`}
          >
            Repay
          </button>
          {/* liquidate buttton to navigate to liquidate page where only loans with liquidity eligible */}
          <button className="tw-px-4 tw-py-2 tw-rounded tw-text-white tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb]">
            Liquidate
          </button>
        </div>

        {showManageLoan || selectedLoan ? (
          <ManageLoan selectedLoan={loanToUse} />
        ) : (
          <div
            id="borrowMain"
            className="tw-grid tw-items-center main-content tw-relative tw-bg-black"
          >
            <p className="tw-text-white tw-text-center tw-mb-0">
              Loan Contract:{" "}
              <span className="tw-text-blue-500 tw-text-xl">
                {contractAddress}
              </span>{" "}
              User Wallet:{" "}
              <span className="tw-text-blue-500 tw-text-xl"> Demo Wallet</span>
            </p>
            <div
              id="borrowMain3"
              className="tw-hidden lg:tw-grid lg:tw-grid-cols-3 gap-4 tw-items-center"
            >
              {/* Deposit Collateral Container */}
              {renderContainer("Deposit")}
              {/* Borrow Container */}
              {renderContainer("Borrow")}
              {/* Loan Stats Container */}
              {renderContainer("LoanStats")}
            </div>{" "}
            && (
            <div className="tw-block lg:tw-hidden">
              {renderContainer(activeContainer)}
            </div>
            <div className="fixed-footer lg:tw-hidden">
              <button
                onClick={() => setActiveContainer("Deposit")}
                className={`tw-p-2 tw-rounded ${
                  activeContainer === "Deposit"
                    ? "tw-bg-green-500"
                    : "tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb]"
                }`}
              >
                <FaChartBar size={24} className="tw-text-white" />
              </button>

              <button
                onClick={() => setActiveContainer("Borrow")}
                className={`tw-p-2 tw-rounded ${
                  activeContainer === "Borrow"
                    ? "tw-bg-green-500"
                    : "tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb]"
                }`}
              >
                <FaMoneyBillWave size={24} className="tw-text-white" />{" "}
              </button>
              <button
                onClick={() => setActiveContainer("LoanStats")}
                className={`tw-p-2 tw-rounded ${
                  activeContainer === "LoanStats"
                    ? "tw-bg-green-500"
                    : "tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb]"
                }`}
              >
                <FaChartLine size={24} className="tw-text-white" />
              </button>
            </div>
          </div>
        )}

        <div className="tw-overflow-auto tw-mt-6 tw-w-full tw-h-full">
          <h1 className="tw-text-2xl tw-mb-0 tw-font-poppins tw-text-[#2DB0F3] tw-text-center">
            All Loans
          </h1>
        </div>
        <div className="tw-overflow-auto tw-w-full tw-h-full">
          <AllLoans
            loans={loans}
            columns={selectedColumns}
            onRowClick={handleRowClick}
          />
        </div>
      </div>
    </div>
  );
}

export default Loans;
