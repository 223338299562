import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="tw-bg-[#2CBEF6] tw-items-center tw-h-15 tw-flex tw-justify-between tw-justify tw-bottom-0 tw-w-full tw-fixed sm:tw-fixed md:tw-fixed">
      <div className="tw-flex tw-px-4 md:tw-px-16 lg:tw-px-32 ">
        <div className="tw-flex tw-items-center tw-mr-2">
          <img
            src={"https://i.postimg.cc/y6GpjFMG/coin.png"}
            className="tw-object-contain tw-w-[40px]"
            alt="EBM Token Logo"
          />
        </div>
        <div className="sm:tw-hidden tw-hidden lg:tw-flex tw-flex-col tw-ml-1 tw-justify-center">
          <p className="tw-text-white tw-text-sm md:tw-text-base">
            CA <span className="tw-text-black">EVM & BSC</span>:
            <a href="https://www.bscscan.com/token/0x43ed084aadc897ff94f41d0dac02a9571dc8339f" 
            target= "_blank">0x43ed084aadc897ff94f41d0dac02a9571dc8339f</a>
          </p>
          <p className="tw-text-white tw-text-sm md:tw-text-base">
            CA <span className="tw-text-black">Solana</span>: Coming Soon.
          </p>
        </div>
        <div className=" lg:tw-hidden tw-flex tw-flex-col tw-ml-1 tw-justify-center">
          <p className="tw-text-white tw-text-sm md:tw-text-base">
            <span className="tw-text-black">EVM & BSC</span>:
            <a href="https://www.bscscan.com/token/0x43ed084aadc897ff94f41d0dac02a9571dc8339f" 
            target= "_blank">0x43ed.....c8339f</a>
          </p>
          <p className="tw-text-white tw-text-sm md:tw-text-base">
            <span className="tw-text-black">Solana</span>: Coming Soon.
          </p>
        </div>

      </div>
      <div className="tw-flex tw-items-center">
        <a
          href="https://t.me/ebmavenuellc"
          target="_blank"
          rel="noopener noreferrer"
          className="tw-mx-2"
        >
          <i className="fab fa-telegram-plane tw-text-2xl"></i>
        </a>
        <a
          href="https://twitter.com/ebmavenuellc"
          target="_blank"
          rel="noopener noreferrer"
          className="tw-mx-2"
        >
          <i className="fab fa-twitter tw-text-2xl"></i>
        </a>
        <a
          href="https://facebook.com/ebmavenuellc"
          target="_blank"
          rel="noopener noreferrer"
          className="tw-mx-2"
        >
          <i className="fab fa-facebook-f tw-text-2xl"></i>
        </a>
        
        <a
          href="https://linkedin.com/company/ebmavenuellc"
          target="_blank"
          rel="noopener noreferrer"
          className="tw-mx-2"
        >
          <i className="fab fa-linkedin-in tw-text-2xl"></i>
        </a>
      </div>
      <div className=" tw-flex tw-items-center tw-pr-4 md:tw-pr-16 lg:tw-pr-32 ">
        <ul className="tw-flex tw-gap-2 tw-font-[Poppins] tw-text-white">
          <li>
            <Link
              to="https://docs.ebmavenue.io"
              target="_blank"
              rel="noopener noreferrer"
              className="tw-text-white tw-text-sm md:tw-text-base"
            >
              Docs
            </Link>
          </li>
          
          <li>
            <Link
              to="https://ebm.farms"
              target="_blank"
              rel="noopener noreferrer"
              className="tw-text-white tw-text-sm md:tw-text-base"
            >
              Web3
            </Link>
          </li>
          {/* Add more links as needed */}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
