// src/tokenData.js

export const tokens = [
    {
    name: 'BNB',
    quantity: 1000,
    price: 712.00,
    maxltv: 80,
    threshold: 90,
    totallocked: 300,
    totalborrowedUSDS: 600000,
    logo: 'https://cryptologos.cc/logos/binance-coin-bnb-logo.png', 
       
    },
    {
    name: 'WETH',
    quantity: 1000,
    price: 3080,
    maxltv: 80,
    threshold: 90,
    totallocked: 300,
    totalborrowedUSDS: 95000,
    logo: 'https://cryptologos.cc/logos/ethereum-eth-logo.png',
    },
    {
        name: 'DOGE',
        quantity: 10000000,
        price: 0.35,
        maxltv: 80,
        threshold: 90,
        totallocked: 300,
        totalborrowedUSDS: 95000,
        logo: 'https://cryptologos.cc/logos/dogecoin-doge-logo.png',
    },
    {
        name: 'WIF',
        quantity: 1000,
        price: 1.9,
        maxltv: 80,
        threshold: 90,
        totallocked: 300,
        totalborrowedUSDS: 95000,
        logo: 'https://cryptologos.cc/logos/dogwifhat-wif-logo.png',
    },
    {
    name: 'EBM',
    quantity: 100000,
    price: 0.002,
    maxltv: 80,
    threshold: 90,
    totallocked: 0,
    totalborrowedUSDS: 0,
    logo: 'https://i.postimg.cc/v81ncPfD/EBM-Crypto-Logo.png', // Replace with actual logo URL
    },
    {
    name: 'USDS',
    quantity: 1000,
    price: 1.00,
    maxltv: 0,
    threshold: 90,
    totallocked: 0,
    totalborrowedUSDS: 0,
    logo: 'https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.png',
    },
    {
    name: 'WBTC',
    quantity: 2,
    price: 101000.00,
    maxltv: 75,
    threshold: 90,
    totallocked: 2,
    totalborrowedUSDS: 180000,
    logo: 'https://cryptologos.cc/logos/wrapped-bitcoin-wbtc-logo.png',
    },
    ];

