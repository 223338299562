import React, { useContext } from "react";
import { useTable, useSortBy } from "react-table";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { CollateralsContext } from "../CollateralsContext"; // Import CollateralsContext

const LoansMain = () => {
  const navigate = useNavigate(); // Initialize useNavigate for navigation
  const { collaterals, setCollaterals } = useContext(CollateralsContext); // Use collaterals from context

  const columns = React.useMemo(
    () => [
      {
        Header: "Logo",
        accessor: "logo",
        Cell: ({ cell: { value } }) => (
          <img src={value} alt="logo" style={{ width: "30px" }} />
        ),
      },
      {
        Header: "Collateral Name",
        accessor: "name",
      },
      {
        Header: "Max LTV",
        accessor: "maxltv",
      },
      {
        Header: "Total Value Locked (USD)",
        accessor: "totallocked",
        Cell: ({ row }) => {
          const token = collaterals.find(token => token.name === row.original.name);
          return token ? (token.totallocked * token.price).toFixed(2) : 'N/A';
        },
      },
      {
        Header: "Total USDS Borrowed",
        accessor: "totalborrowedUSDS",
      },
    ],
    [collaterals]
  );

  const data = React.useMemo(() => collaterals, [collaterals]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  const handleRowClick = (collateralName) => {
    navigate(`/loans/${collateralName}`);
  };

  return (
    <div className="tw-bg-Hero tw-bg-cover tw-relative tw-bg-black tw-bg-center tw-w-full tw-min-h-screen">
      <div className="tw-absolute tw-top-0 tw-left-0">
        <img src={"https://i.postimg.cc/d3bMzs0h/left-image.png"} alt="Aqua color lighting from the left" />
      </div>
      <div className="tw-absolute tw-top-0 tw-right-0">
        <img src={"https://i.postimg.cc/8kMV6vXW/right-image.png"} alt="Aqua color lighting from the right" />
      </div>
      
      <div className="container tw-relative tw-pt-6 tw-pb-28 tw-px-4 md:tw-px-16 lg:tw-px-32 tw-min-h-screen">

      <h1 className=" tw-text-[#2DB0F3] tw-font-bold md:tw-text-2xl tw-text-xl tw-text-center tw-mb-8 tw-mt-2">Interest Free Loans</h1>

      <p className="tw-text-center sm:tw-text-xl lg:sm:tw-text-2xl tw-mb-6 tw-font-poppins tw-text-[#2DB0F3] tw-text-white"> EBM's Lending protocoal has a Master Contract, which serves as the backbone for efficient lending, maintaining central funds and a comprehensive list of collaterals with their respective Loan-to-Value (LTV) ratios.

This centralized yet isolated liquidity model ensures efficient fund management and streamlined loan processing.</p>

        <h1 className="tw-text-[#2DB0F3] tw-font-semibold md:tw-text-2xl tw-text-xl tw-text-center tw-mb-1 tw-mt-8">Collaterals</h1>
        <p className="tw-text-center sm:tw-text-xl lg:sm:tw-text-2xl tw-mb-1 tw-font-poppins tw-text-[#2DB0F3] tw-text-white"> Simply select your collateral to proceed to "Manage Loans" Page.</p>
        <div className="tw-overflow-auto tw-w-full tw-h-full tw-py-6">
          <table {...getTableProps()} className="tw-w-full tw-bg-black tw-text-white tw-border tw-border-[#2DB0F3]">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className="tw-border tw-border-[#2DB0F3] tw-px-4 tw-py-2">
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} onClick={() => handleRowClick(row.original.name)} className="tw-cursor-pointer">
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()} className="tw-border tw-border-[#2DB0F3] tw-px-4 tw-py-2">
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LoansMain;