import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Dashboard from "./components/Dashboard";
import Loans from "./components/Loans";
import LoansMain from "./components/Loansmain";
import Swap4Less from "./components/Swap4Less";
import Rewards from "./components/Rewards";
import ProtectedRoute from "./components/ProtectedRoute";
import { CollateralsProvider } from "./CollateralsContext";
import { WalletProvider } from "./WalletContext";


function App() {
  return (
    <WalletProvider>
      <CollateralsProvider>
      <Router>
      <Header />
        <div className="">
        <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/LoansMain" element={<LoansMain />} />
            <Route path="/loans/:collateralName" element={<Loans />} />
            <Route path="/Swap4Less" element={<Swap4Less />} />
            <Route path="/Rewards" element={<Rewards />} />
          </Routes>
        </div>
        <Footer />
      </Router>
      </CollateralsProvider>
    </WalletProvider>
  );
}

export default App;