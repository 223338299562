import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Footer from "./Footer";

import { WalletContext } from "../WalletContext";


function Home() {
  const { walletConnected, handleConnect } = useContext(WalletContext);
  const navigate = useNavigate();

  const connectAndNavigate = () => {
    handleConnect();
    navigate("/dashboard");
  };

  return (
    <div className="tw-bg-Hero tw-bg-cover tw-relative tw-bg-black tw-bg-center tw-w-full tw-h-screen">
      <div className="tw-absolute tw-top-0 tw-left-0">
        <img
          src={"https://i.postimg.cc/d3bMzs0h/left-image.png"}
          alt="Aqua color lighting from the left"
        />
      </div>
      <div className="tw-absolute tw-top-0 tw-right-0">
        <img
          src={"https://i.postimg.cc/8kMV6vXW/right-image.png"}
          alt="Aqua color lighting from the right"
        />
      </div>
     
      <div className="container tw-relative  tw-pt-6 tw-pb-4 tw-px-4 md:tw-px-16 lg:tw-px-32">
        <div className="row g-8 tw-items-center tw-mt-5">
        <div className="tw-text-4xl tw-mb-6 tw-font-poppins tw-text-[#2DB0F3] col-lg-6 col-md-14 tw-text-center tw-items-center tw-mb-10"> Welcome to EBM Farms by EBM Avenue</div>
          <div className="col-lg-6 col-md-14 tw-text-center tw-items-center">
            <h1 className="tw-text-white tw-font-semibold md:tw-text-6xl tw-text-2xl">
              {walletConnected ? "Wallet Connected" : "No Wallet Connected"}
            </h1>
            <p className="tw-text-white tw-leading-7 tw-pt-0 tw-mb-5 tw-text-xl">
              {walletConnected 
                ? "Proceed to the dashboard or explore other features."
                : "Please connect your wallet to see your supplies, borrowings, and open positions."
              }
            </p>
            <div className="tw-flex tw-justify-center sm:tw-mt-5 lg:tw-mt-20 tw-mb-10">
              <img src={"https://i.postimg.cc/k5jpWKWK/EBM-CRYPTO-WALLET.png"} className="tw-object-contain tw-w-[250px]" alt="Wallet_blue" />
              </div>
            {!walletConnected && (
              <Button
                variant="contained"
                className="bg-green-500 text-white"
                onClick={connectAndNavigate}
              >
                <span style={{ 
                  display: 'inline-block', 
                  width: '10px', 
                  height: '10px', 
                  backgroundColor: 'green', 
                  borderRadius: '50%', 
                  marginRight: '8px', 
                  
                }}></span>
                Connect Demo Wallet
              </Button>
            )}
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Home;