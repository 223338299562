import React, { useState, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { PieChart, Pie, Cell, Tooltip, Legend, Label } from "recharts";
import { WalletContext } from "../WalletContext"; // Import the WalletContext

import AllLoans from "../components/AllLoans";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF6384"];

function Dashboard({ handleConnect, handleDisconnect }) {
  const { tokens, loans, columns } = useContext(WalletContext);
  const [selectedLoan, setSelectedLoan] = useState(null);

  const selectedColumns = columns.filter((column) =>
    [
      "contract",
      "collateralName",
      "collateralDeposit",
      "borrowedToken",
      "borrowedAmount",
      "dailyFee",
      "accumulatedFee",
      "totalRepay",
      "remainingDuration",
      "healthStatus",
      "liquidationPrice",
    ].includes(column.key)
  );

  const tokenData = useMemo(
    () =>
      tokens.map((token) => ({
        name: token.name,
        value: token.quantity * token.price,
      })),
    [tokens]
  );
  const totalValue = tokenData.reduce((acc, entry) => acc + entry.value, 0);

  const navigate = useNavigate();

  const handleRowClick = (loan) => {
    setSelectedLoan(loan);
    navigate(`/loans/${loan.collateralName}`, {
      state: { selectedLoan: loan },
    });
  };

  return (
    <div className="tw-bg-Hero tw-bg-cover tw-relative tw-bg-black tw-bg-center tw-w-full tw-h-auto">
      <div className="tw-absolute tw-top-0 tw-left-0">
        <img
          src={"https://i.postimg.cc/d3bMzs0h/left-image.png"}
          alt="Aqua color lighting from the left"
        />
      </div>
      <div className="tw-absolute tw-top-0 tw-right-0">
        <img
          src={"https://i.postimg.cc/8kMV6vXW/right-image.png"}
          alt="Aqua color lighting from the right"
        />
      </div>
      <div className="container tw-relative tw-pt-6 tw-pb-28 tw-px-4 md:tw-px-16 lg:tw-px-32">
        <div className="tw-flex tw-relative tw-flex-col md:tw-flex-row tw-w-full tw-h-full tw-mb-4">
          <div className="tw-flex-1 tw-p-6 tw-items-center tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-text-white  tw-shadow-lg tw-mb-4 md:tw-mb-0 md:tw-ml-4">
            <h1 className="tw-text-white tw-font-semibold md:tw-text-xl tw-text-center">
              Portfolio
            </h1>
            <div className="tw-flex tw-justify-center tw-items-center tw-h-full">
              <PieChart
                width={400}
                height={400}
                className="tw-w-full tw-h-full"
              >
                <Pie
                  data={tokenData}
                  cx="50%"
                  cy="50%"
                  innerRadius={100}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="value"
                >
                  <Label
                    value={`Total in USD: ${totalValue}`}
                    position="center"
                    fill="#fff"
                  />
                  {tokenData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>

                <Tooltip />
                <Legend
                  layout="horizontal"
                  align="center"
                  verticalAlign="bottom"
                />
              </PieChart>
            </div>
          </div>

          <div className="tw-flex-1 tw-relative tw-p-2 tw-w-full tw-items-center tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-text-white tw-shadow-lg tw-mb-0">
            <h1 className="tw-text-white tw-font-semibold md:tw-text-xl tw-text-center">
              Asset Balances
            </h1>
            <div className="tw-flex-1 tw-justify-center tw-items-center tw-h-auto tw-relative tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-bg-black tw-shadow-lg">
              <h1 className="tw-text-white tw-font-semibold md:tw-text-xl tw-text-center">
                Total in USD: ${totalValue}
              </h1>
              <div className="tw-flex tw-relative  tw-w-full tw-mt-3">
                <table className="tw-w-full tw-mt-3 ">
                  <thead>
                    <tr >
                      <th className="tw-px-1 tw-py-1 tw-text-sm md:tw-text-base tw-text-start lg:tw-px-12 ">Asset</th>
                      <th className="tw-px-1 tw-py-1 tw-text-sm md:tw-text-base tw-text-start  ">Quantity</th>
                      <th className="tw-px-1 tw-py-1 tw-text-sm md:tw-text-base tw-text-start  ">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tokens.map((token, index) => (
                      <tr key={index}>
                        <td className="tw-px-1 tw-py-1 tw-text-sm md:tw-text-base">
                          <div className="tw-flex tw-px-1 tw-py-1 lg:tw-ml-8 tw-text-sm md:tw-text-base">
                            <img
                              src={token.logo}
                              alt={`${token.name} logo`}
                              className="tw-w-5 tw-mr-2"
                            />
                            {token.name} {token.price} $
                          </div>
                        </td>
                        <td className="tw-px-1 tw-py-1 tw-text-sm md:tw-text-base">
                          {token.quantity}
                        </td>
                        <td className="tw-px-1 tw-py-1 tw-text-sm md:tw-text-base">
                          {Math.round(token.quantity * token.price).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="tw-overflow-auto tw-mt-4 tw-w-full tw-h-full ">
          <h1 className="tw-text-2xl tw-mb-0 tw-font-poppins tw-text-[#2DB0F3] tw-text-center">
            All Loans
          </h1>
        </div>
        <div className="tw-overflow-auto tw-w-full tw-h-full">
          <AllLoans
            loans={loans}
            columns={selectedColumns}
            onRowClick={handleRowClick}
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
