import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { WalletContext } from "../WalletContext";
import { MdMenu, MdOutlineClose } from "react-icons/md";

const Header = () => {
  const { walletConnected, handleDisconnect, handleConnect } =
    useContext(WalletContext);
  const [scrollBackground, setScrollBackground] = useState(false);
  const [open1, setOpen1] = useState(false); // State to manage mobile menu
  const location = useLocation();

  const isActive = (path) => location.pathname === path;
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrollBackground(true);
      } else {
        setScrollBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`tw-top-0 tw-w-full tw-z-20 ${
        scrollBackground ? "tw-bg-black sm:tw-bg-transparent tw-fixed sm:tw-fixed md:tw-fixed" : ""
      } `}
    >
      <div className="tw-flex tw-items-center tw-bg-black tw-font-medium tw-h-20 container tw-mx-auto tw-justify-between tw-px-6 md:tw-px-20 lg:tw-px-32">
        <div className="tw-items-start">
          <img
            src={require("../assets/images/EBM-Avenue-logo.png")}
            className="tw-object-contain tw-w-[180px]"
            alt="EBM-Avenue-Logo"
          />
        </div>
        <div className="lg:tw-flex  tw-flex tw-justify-right tw-gap-2">
          <button className="tw-px-1 tw-py-0 tw-rounded-full tw-bg-[#2CBEF6] tw-text-white">
            Demo
          </button>
          <button
            className="tw-px-1 tw-py-1 tw-rounded-full tw-bg-gray-300 tw-text-gray-500"
            disabled
          >
            Testnets
          </button>
          <button
            className="tw-px-1 tw-py-1 tw-rounded-full tw-bg-gray-300 tw-text-gray-500"
            disabled
          >
            Mainnets
          </button>
        </div>
        <ul className="lg:tw-flex tw-hidden tw-items-center tw-gap-8 tw-font-[Poppins]">
          {walletConnected && (
            <li>
              <Link
                className={`${
                  isActive("/") ? "tw-text-[#2CBEF6]" : "tw-text-white"
                } tw-text-white`}
                to={"/dashboard"}
              >
                Dashboard
              </Link>
            </li>
          )}
          <li>
            <Link to={"/LoansMain"} className="tw-text-white">
              Loans
            </Link>
          </li>
          <li>
            <Link to={"/Swap4Less"} className="tw-text-white">
              Swaps
            </Link>
          </li>
          <li>
            <Link to={"/Rewards"} className="tw-text-white">
              Rewards
            </Link>
          </li>
          {walletConnected ? (
            <Button
              variant="contained"
              className="bg-red-500 text-white"
              onClick={handleDisconnect}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "15px",
                  height: "15px",
                  backgroundColor: "red",
                  borderRadius: "80%",
                  marginRight: "8px",
                }}
              ></span>
              Demo Wallet
            </Button>
          ) : (
            <Button
              variant="contained"
              className="bg-green-500 text-white"
              onClick={handleConnect}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "15px",
                  height: "15px",
                  backgroundColor: "green",
                  borderRadius: "80%",
                  marginRight: "8px",
                }}
              ></span>
              Demo Wallet
            </Button>
          )}
        </ul>
        {/* Mobile nav button */}
        <div
          className="tw-text-3xl lg:tw-hidden tw-pt-0 tw-z-50 tw-cursor-pointer"
          onClick={() => setOpen1(!open1)}
        >
          {open1 ? <MdOutlineClose color="white" /> : <MdMenu color="white" />}
        </div>

        {/* Mobile nav */}
        <div
              className={` 
                lg:tw-hidden  tw-z-40  tw-bg-black  tw-bg-cover  tw-bg-Hero tw-fixed tw-w-full tw-top-0 tw-overflow-y-auto tw-bottom-0 tw-leading-10 tw-py-20 
                tw-duration-500 ${open1 ? "tw-left-0" : "tw-left-[-100%]"}`
              }
              >
          <div className="tw-absolute tw-h-screen tw-top-0 tw-left-0">
        <img
          src={"https://i.postimg.cc/d3bMzs0h/left-image.png"}
          alt="Aqua color lighting from the left"
        />
      </div>
      <div className="tw-absolute tw-h-screen tw-top-0 tw-right-0">
        <img
          src={"https://i.postimg.cc/8kMV6vXW/right-image.png"}
          alt="Aqua color lighting from the right"
        />
      </div>
          <div className="tw-pb-5 tw-px-8">
            <img
              src={require("../assets/images/EBM-Avenue-logo.png")}
              className="tw-object-contain tw-w-[180px]"
              alt="EBM Logo"
            />
          </div>
          <ul className="tw-p-0 tw-relative tw-px-9 tw-pt-3 tw-border-t">
            {walletConnected && (
              <li>
                <Link
                  className={`${
                    isActive("/dashboard") ? "tw-text-[#2CBEF6]" : "tw-text-white"
                  } tw-text-white`}
                  to="/dashboard"
                onClick={() => {
                  setOpen1(false);
                }}
                >
                  Dashboard
                </Link>
              </li>
            )}
            <li>
              <Link
                className={`tw-text-white ${
                  isActive("/LoansMain") ? "tw-text-[#2CBEF6]" : ""
                }`}
                to="/LoansMain"
                onClick={() => {
                  setOpen1(false);
                }} // Close menu on link click
              >
                Loans
              </Link>
            </li>
            <li>
            <Link
                className={`tw-text-white ${
                  isActive("/Swap4Less") ? "tw-text-[#2CBEF6]" : ""
                }`}
                to="/Swap4Less"
                onClick={() => {
                  setOpen1(false);
                }} // Close menu on link click
              >
                Swaps
              </Link>
            </li>
            <li>
            <Link
                className={`tw-text-white ${
                  isActive("/Rewards") ? "tw-text-[#2CBEF6]" : ""
                }`}
                to="/Rewards"
                onClick={() => {
                  setOpen1(false);
                }} // Close menu on link click
              >
                Rewards
              </Link>
            </li>
            {walletConnected ? (
              <Button
                variant="contained"
                className="bg-red-500 text-white"
                onClick={handleDisconnect}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: "15px",
                    height: "15px",
                    backgroundColor: "red",
                    borderRadius: "80%",
                    marginRight: "8px",
                  }}
                ></span>
                Demo Wallet
              </Button>
            ) : (
              <Button
                variant="contained"
                className="bg-green-500 text-white"
                onClick={handleConnect}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: "15px",
                    height: "15px",
                    backgroundColor: "green",
                    borderRadius: "80%",
                    marginRight: "8px",
                  }}
                ></span>
                Demo Wallet
              </Button>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
