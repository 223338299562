import React, { createContext, useState, useEffect } from "react";
import axios from 'axios';
import { collaterals as initialTokens } from "./collateralData"; // Import initial tokens

export const CollateralsContext = createContext();

export const CollateralsProvider = ({ children }) => {
  const [collaterals, setCollaterals] = useState(initialTokens);

  useEffect(() => {
    // Fetch token prices and update collaterals state
    const fetchTokenPrices = async () => {
      try {
        const response = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
          params: {
            ids: 'binancecoin,ethereum,dogecoin,dogwifcoin,bitcoin',
            vs_currencies: 'usd',
          },
        });

        const prices = response.data;
        const updatedCollaterals = initialTokens.map(token => {
          switch (token.name) {
            case 'BNB':
              return { ...token, price: prices.binancecoin.usd };
            case 'WBTC':
              return { ...token, price: prices.bitcoin.usd };
            case 'WETH':
              return { ...token, price: prices.ethereum.usd };
            case 'DOGE':
              return { ...token, price: prices.dogecoin.usd };
            case 'WIF':
              return { ...token, price: prices.dogwifcoin.usd };
            default:
              return token;
          }
        });

        setCollaterals(updatedCollaterals);
      } catch (error) {
        console.error('Error fetching token prices:', error);
      }
    };

    fetchTokenPrices();
  }, []);

  return (
    <CollateralsContext.Provider value={{ collaterals, setCollaterals }}>
      {children}
    </CollateralsContext.Provider>
  );
};