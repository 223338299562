import React from "react";

const Rewards = () => {
  return (
    <div className="tw-bg-Hero tw-bg-cover tw-relative tw-bg-black tw-bg-center tw-w-full tw-min-h-screen">
      <div className="tw-absolute tw-top-0 tw-left-0">
        <img
          src={"https://i.postimg.cc/d3bMzs0h/left-image.png"}
          alt="Aqua color lighting from the left"
        />
      </div>
      <div className="tw-absolute tw-top-0 tw-right-0">
        <img
          src={"https://i.postimg.cc/8kMV6vXW/right-image.png"}
          alt="Aqua color lighting from the right"
        />
      </div>
      
      <div className="container tw-relative tw-pt-6 tw-pb-28 tw-px-4 md:tw-px-16 lg:tw-px-32 tw-min-h-screen">
        <h1 className="tw-text-gradient tw-font-semibold md:tw-text-2xl tw-text-xl tw-text-center tw-mb-16 tw-mt-10 tw-h-auto tw-bg-[#2CBEF6]">Stake 4 More Rewards </h1>
        <h1 className="tw-text-white tw-font-semibold md:tw-text-2xl tw-text-xl tw-text-center tw-mb-10 tw-mt-10 tw-h-auto ">Stake $EBM For Higher Crypto Discounts, 0% Loans, and Rewards</h1>

        <div className="tw-overflow-auto tw-mt-4 tw-w-full tw-h-full tw-py-6">
          <div className="tw-text-white tw-font-semibold md:tw-text-2xl tw-text-xl tw-text-center tw-mb-8 tw-mt-8 tw-h-auto">
            Coming Soon
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rewards;