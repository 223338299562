
/*
  The AllLoans component is a table that displays a list of loans. 
  Remember this table of loans has to be integrated with Walletcontext. So that upon receiviing the loan the tokens in the wallet will be updated and the loan will be added to the loans array in the walletcontext.
  Because this is just a demo system it always start with empty table of loans with headers and no rows of loans.
  
  With Headers: Contract, user, Collateral Name, Collateral Deposited, Collateral Value, Borrowed Amount, Loan Duration, Remaining Duration, Daily Fee, Accumulated Fee, Total Repay, Health Factor, Health Status, Liquidation Date, Liquidation Price, Liquidation Fee, Liquidation Fee in USDS.
  with following props or parameters: 
  - Contract
  - user // this is always demo user.
  - CollateralName
  - CollateralDeposited
  - collateralValue // this will be alway shows basing on the current price of the collateral token which will be updated whenever we implement the price update functionality.
  - borrowedAmount
  - loanduration
  - remainingDuration
  - dailyFee
  - accumulatedFee
  - totalRepay
  - healthFactor
  - healthStatus
  - liquidationdate
  - liquidationPrice
  - liquidationFee
  - liquidationFeeinUSDS // this will be calculated by multiplying liquidationFee with the current price of the collateral token and keeps updating whenever we implement the price update functionality.
  
  onRowClick // this will be a function that will be called when a row is clicked
  on clicking any row of this table, the user will be shown the manage container within the loans page which normally opens with collaternal name parameter in the url. 
  And in place of borrow container with details of selected loan which can be identified by the loan contract.

  The AllLoans component will have a sorting functionality that will allow the user to sort the loans by any column in ascending or descending order.
  The sorting functionality will be implemented using the useState and useMemo hooks.
  The sortedLoans variable will be created using the useMemo hook to sort the loans based on the sortConfig state.
  The requestSort function will update the sortConfig state based on the column key and direction.
  The table headers will have an onClick event handler that will call the requestSort function with the column key.
  The table rows will have an onClick event handler that will call the onRowClick function with the selected loan data.

  The AllLoans component will be used to display a list of loans in the user dashboard or loans page.
  It can also be displayed under the loans page itself filtered to the collateral in the loans page.
  It can also be displayed by selecting only the specific columns required to display in the loans page.
  A user can view the details of their loans and click on a loan to manage or repay the loan
  The AllLoans component will display the loan details in a table format with columns such as Contract, User, Collateral Name, Collateral Deposited, Borrowed Amount, Loan Duration, Remaining Duration, Daily Fee, Accumulated Fee, Total Repay, Health Factor, Health Status, Liquidation Date, Liquidation Price, Liquidation Fee, Liquidation Fee in USDS
  

*/
import React, { useState, useMemo } from "react";

const AllLoans = ({ loans, columns, onRowClick }) => {
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedLoans = useMemo(() => {
    let sortableLoans = [...loans];
    if (sortConfig.key !== "") {
      sortableLoans.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableLoans;
  }, [loans, sortConfig]);

  return (
    <div className="tw-pt-6 tw-pb-28 tw-relative tw-mt-0 tw-bg-black tw-w-full tw-h-auto tw-px-2 ">
      
      <table className="tw-w-full tw-bg-black tw-text-white tw-border tw-border-[#2DB0F3] ">
        <thead className="tw-bg-[#2DB0F3]">
          <tr>
            {columns.map((column) => (
              <th
                key={column.key}
                className="tw-border tw-border-black tw-px-4 tw-py-2 cursor-pointer"
                onClick={() => requestSort(column.key)}
              >
                {column.label} {sortConfig.key === column.key ? (sortConfig.direction === "ascending" ? "▲" : "▼") : ""}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedLoans.map((loan, index) => (
            <tr
              key={index}
              onClick={() => onRowClick(loan)} // Add onClick event handler
              className="tw-cursor-pointer" // Add cursor-pointer class
            >
              {columns.map((column) => (
                <td key={column.key} className="tw-border tw-border-[#2DB0F3] tw-px-4 tw-py-2">
                  {loan[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllLoans;