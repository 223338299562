import React, { createContext, useState, useEffect } from "react";
import { tokens as initialTokens } from "./tokenData";
import axios from 'axios';


export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [walletConnected, setWalletConnected] = useState(false);
  const [tokens, setTokens] = useState(initialTokens);
  const [loans, setLoans] = useState([]); // Initialize loans as an empty array
  const [openPositions, setOpenPositions] = useState([]);


  const columns = [
    { key: "contract", label: "Contract" },
    { key: "user", label: "User" },
    { key: "collateralName", label: "Collateral Name" },
    { key: "collateralDeposit", label: "Collateral Deposited" },
    { key: "borrowedToken", label: "Borrowed Curr." },
    { key: "borrowedAmount", label: "Borrowed Amount" },
    { key: "loanDuration", label: "Loan Duration" },
    { key: "remainingDuration", label: "Remaining Duration" },
    { key: "dailyFee", label: "Daily Fee" },
    { key: "accumulatedFee", label: "Accumulated Fee" },
    { key: "totalRepay", label: "Total Repay" },
    { key: "healthFactor", label: "Health Factor" },
    { key: "healthStatus", label: "Health Status" },
    { key: "liquidationdate", label: "Liquidation Date" },
    { key: "liquidationPrice", label: "Liquidation Price" },
    { key: "liquidationFee", label: "Liquidation Fee" },
    { key: "liquidatorAccess", label: "Liquidator Access" },
    { key: "liquidationFeeinUSDS", label: "Liquidation Fee in USDS" },
  ];

  const handleConnect = () => {
    setWalletConnected(true);
  };

  const handleDisconnect = () => {
    setWalletConnected(false);
  };



  useEffect(() => {
    // Fetch token prices and update tokens state
    const fetchTokenPrices = async () => {
      try {
        const response = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
          params: {
            ids: 'binancecoin,ethereum,dogecoin,bitcoin,dogwifcoin',
            vs_currencies: 'usd',
          },
        });

        const prices = response.data;
        const updatedTokens = tokens.map(token => {
          switch (token.name) {
            case 'BNB':
              return { ...token, price: prices.binancecoin.usd };
              case 'WBTC':
                return { ...token, price: prices.bitcoin.usd };
            case 'WETH':
              return { ...token, price: prices.ethereum.usd };
            case 'DOGE':
              return { ...token, price: prices.dogecoin.usd };
              case 'WIF':
                return { ...token, price: prices.dogwifcoin.usd };
            default:
              return token;
          }
        });

        setTokens(updatedTokens);
      } catch (error) {
        console.error('Error fetching token prices:', error);
      }
    };

    fetchTokenPrices();
  }, []);


   return (
    <WalletContext.Provider
      value={{
        walletConnected,
        tokens,
        loans,
        openPositions,
        columns, // Provide columns in the context
        setTokens,

        setLoans,
        setOpenPositions,
        handleConnect,
        handleDisconnect,
       
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};