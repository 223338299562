import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const LoanHealthGauge = ({ value }) => {
  const getGaugeText = (value) => {
    if (value >= 1.5) return "Good";
    if (value >= 1.3 && value <= 1.49) return "Moderate";
    if (value >= 1.1 && value <= 1.29) return "Risky";
    return "Liq. eligible";
  };

  const getGaugeColor = (value) => {
    if (value >= 1.5) return "green";
    if (value >= 1.3 && value <= 1.49) return "yellow";
    if (value >= 1.1 && value <= 1.29) return "orange";
    return "red";
  };

  const percentage = (value / 2) * 100; // Assuming value is between 0 and 2

  return (
    <div className="tw-items-center"style={{ width: '100%', height: '150px', position: 'relative', overflow: 'hidden' }}>
      <div >
        <CircularProgressbarWithChildren
          value={percentage}
          styles={buildStyles({
            rotation: 0.25, 
            strokeLinecap: 'butt',
            trailColor: '#D3D3D3',
            pathColor: getGaugeColor(value),
            textColor: '#FFFFFF',
            textSize: '12px',
            pathTransitionDuration: 0.0,
          })}
        >
          <div style={{
                       
            transform: 'translateY(90%)',
            width: '100%',
            textAlign: 'center',
            color: '#FFFFFF',
            fontSize: 14,
            position: 'absolute',
            top: 20 + '%',
          }}>
            <strong>{getGaugeText(value)}</strong>
          </div>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  );
};

export default LoanHealthGauge;