import React, { useState, useContext, useRef } from "react";
import { WalletContext } from "../WalletContext";
import LoanHealthGauge from "../LoanHealthGauge";
import { FaChartBar, FaMoneyBillWave, FaChartLine } from "react-icons/fa";
import { CollateralsContext } from "../CollateralsContext"; // Import CollateralsContext

const defaultLoan = {
  healthFactor: 0,
  borrowedAmount: 0,
  collateralDeposit: 0,
  collateralName: "",
  collateralPrice: 0,
  accumulatedFee: 0,
  totalRepay: 0,
  healthStatus: "Unknown",
  liquidationDate: "N/A",
  liquidationFee: 0,
  liquidationPrice: 0,
};


const ManageLoan = ({ selectedLoan } ) => {
  const { walletConnected, tokens, setTokens, loans, setLoans } = useContext(WalletContext);
  const { collaterals, setCollaterals } = useContext(CollateralsContext); // Use collaterals from context

  const loan = selectedLoan || defaultLoan;
  const [activeContainer, setActiveContainer] = useState("CurrentLoanStats");

  const [healthFactor, setnewHealthFactor] = useState(selectedLoan.healthFactor);
  const [borrowedAmount, setnewBorrowedAmount] = useState(selectedLoan.borrowedAmount);
  const [repayAmount, setRepayAmount] = useState(0);
  const [withdrawCollAmount, setWithdrawCollAmount] = useState(0);
  const [futureLoanDetails, setFutureLoanDetails] = useState(selectedLoan);
  const [token, setToken] = useState(
    tokens.find((token) => token.name === selectedLoan.collateralName)  || {}
  );
  const currentCollValue = selectedLoan.collateralDeposit * token.price;
  const requiredCollValue = selectedLoan.totalRepay * 1.5;
  const suggestedCollValue = requiredCollValue - currentCollValue;

  const calculateSuggestedCollateralDeposit = () => {
    // Calculate the suggested collateral deposit amount
    if (selectedLoan.healthStatus === "Good") {
      return 0;
    } else {
      const suggesteddeposit = suggestedCollValue / token.price;
      return suggesteddeposit;
    }
  };

  const [collateralDeposit, setCollateralDeposit] = useState(0);


  const handleRepayAmountChange = (e) => {
    const value = parseFloat(e.target.value).toFixed(2);
    if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
      setRepayAmount(value);
      
      updateFutureLoanDetails(collateralDeposit, value, withdrawCollAmount);
    }
  };

  const handleWithdrawAmountChange = (e) => {
    const value = (e.target.value);
    if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
      setWithdrawCollAmount(value);
      
      updateFutureLoanDetails(collateralDeposit, repayAmount, value);
    }
  };

  const handleLtvChange = (e) => {
    const value = parseFloat(e.target.value).toFixed(2);
    setRepayAmount(value);
    updateFutureLoanDetails(collateralDeposit, value, withdrawCollAmount);
  };

  const handleDeposit = () => {
    if (!walletConnected) {
      alert("Please connect your wallet first.");
      return;
    }
    // Update loan details with new collateral deposit
    const updatedLoanDetails = {
      ...selectedLoan,
      collateralDeposit: parseFloat(selectedLoan.collateralDeposit) + parseFloat(collateralDeposit),
    };
    // Update tokens and loan details in context
    const updatedLoans = loans.map((l) => {
      if (l.id === selectedLoan.id) {
        return updatedLoanDetails;
      }
      return l;
    });
    setLoans(updatedLoans);

    setTokens((prevTokens) =>
      prevTokens.map((token) =>
        token.name === selectedLoan.collateralName
          ? { ...token, quantity: token.quantity - collateralDeposit }
          : token
      )
    );
    alert("Collateral deposited");
    setCollateralDeposit(0); // Reset input form
    const updatedCollaterals = collaterals.map((collateral) => {
      if (collateral.name === selectedLoan.collateralName) {
        return { ...collateral, totallocked: collateral.totallocked + collateralDeposit };
      }
      return collateral;
    });
    setCollaterals(updatedCollaterals);
  };

  const handleRepay = () => {
    if (!walletConnected) {
      alert("Please connect your wallet first.");
      return;
    }
    // Update loan details with new repay amount
    const updatedLoanDetails = {
      ...loan,
      borrowedAmount: parseFloat(selectedLoan.borrowedAmount) - parseFloat(repayAmount),
      totalRepay: parseFloat(selectedLoan.totalRepay) - parseFloat(repayAmount),
    };
     // Update the loans state
     const updatedLoans = loans.map((l) => {
      if (l.id === selectedLoan.id) {
        return updatedLoanDetails;
      }
      return l;
    });
    setLoans(updatedLoans);
    alert("Amount repaid");

    //setShowDepositMessage(true);
    const updatedTokens = tokens.map((token) => {
      if (token.name === selectedLoan.borrowedToken) {
        return { ...token, quantity: token.quantity - repayAmount };
      }
      return token;
    });
    setTokens(updatedTokens);
    setRepayAmount(0); // Reset input form
    const updatedCollaterals = collaterals.map((collateral) => {
      if (collateral.name === selectedLoan.collateralName) {
        return { ...collateral, totalborrowedUSDS: collateral.totalborrowedUSDS - repayAmount };
      }
      return collateral;
    });
    setCollaterals(updatedCollaterals);
  };

  const handleWithdraw = () => {
    if (!walletConnected) {
      alert("Please connect your wallet first.");
      return;
    }
    // Update loan details with new withdraw collateral amount
    const updatedLoanDetails = {
      ...loan,
      collateralDeposit: parseFloat(selectedLoan.collateralDeposit) - parseFloat(withdrawCollAmount),
      healthFactor: healthFactor,
    };
    // Update the loans state
    const updatedLoans = loans.map((l) => {
      if (l.id === selectedLoan.id) {
        return updatedLoanDetails;
      }
      return l;
    });
    setLoans(updatedLoans);

    alert("Collateral withdrawn");
    const updatedTokens = tokens.map((token) => {
      if (token.name === selectedLoan.collateralName) {
        return { ...token, quantity: token.quantity + parseFloat(withdrawCollAmount) };
      }
      return token;
    });
    setTokens(updatedTokens);
    setWithdrawCollAmount(0); // Reset input form
    const updatedCollaterals = collaterals.map((collateral) => {
      if (collateral.name === selectedLoan.collateralName) {
        return { ...collateral, totallocked: collateral.totallocked - parseFloat(withdrawCollAmount) };
      }
      return collateral;
    });
    setCollaterals(updatedCollaterals);

  };

  const handleCollateralDepositChange = (e) => {
    const value = (e.target.value);
    
    if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
      
      setCollateralDeposit(value);
      
      updateFutureLoanDetails(value, repayAmount, withdrawCollAmount);
    }
  };
  // Calculate the health factor of the loan using the formula
  const  calculateHealthFactor = (collateralDeposit,borrowedAmount) => {
    const value =   (collateralDeposit*token.price)/ borrowedAmount;
    setnewHealthFactor(parseFloat(value).toFixed(2));
  };
 
  const updateFutureLoanDetails = (
    collateralDeposit,
    repayAmount,
    withdrawCollAmount,
    
  ) => {
    const updatedCollateralDeposit = parseFloat(selectedLoan.collateralDeposit) + parseFloat(collateralDeposit) - parseFloat(withdrawCollAmount);
    const updatedBorrowedAmount = parseFloat(selectedLoan.borrowedAmount) - parseFloat(repayAmount) ;
    calculateHealthFactor(updatedCollateralDeposit, updatedBorrowedAmount);
    const updatedFutureLoanDetails = {
      ...loan,
      collateralDeposit: updatedCollateralDeposit,
      borrowedAmount: updatedBorrowedAmount,
      accumulatedFee: selectedLoan.accumulatedFee,
      totalRepay: updatedBorrowedAmount,
      healthFactor: healthFactor,
      liquidationDate: selectedLoan.liquidationDate,
      liquidationFee: (updatedCollateralDeposit)*0.1,
      liquidationPrice: parseFloat((updatedBorrowedAmount + 5) / (updatedCollateralDeposit*0.8)).toFixed(6),

    };
    setFutureLoanDetails(updatedFutureLoanDetails);
  };

    // Refs for containers
    const currentLoanStatsRef = useRef(null);
    const manageRepayRef = useRef(null);
    const futureLoanStatsRef = useRef(null);

  const renderContainer = (container) => {
    switch (container) {
      case "CurrentLoanStats":
        return (
          <div ref={currentLoanStatsRef} id="CurrentLoanStats" className="tw-flex tw-flex-col tw-items-center tw-p-6 tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-text-white tw-bg-black tw-h-full tw-shadow-lg tw-mt-4">
            <h3 className="tw-text-2xl tw-mb-4 tw-mt-0 tw-font-poppins tw-text-[#2DB0F3]">Current Loan Stats</h3>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              <p className="tw-text-lg">Collateral Amount:</p>
              <p className="tw-text-base tw-font-semibold">{selectedLoan.collateralDeposit}</p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              <p className="tw-text-lg">Collateral Value:</p>
              <p className="tw-text-base tw-font-semibold">{selectedLoan.collateralDeposit * token.price}</p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              <p className="tw-text-lg">Loan Amount:</p>
              <p className="tw-text-base tw-font-semibold">{selectedLoan.borrowedAmount}</p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              <p className="tw-text-lg">Accumulated Admin Fee:</p>
              <p className="tw-text-base tw-font-semibold">{selectedLoan.accumulatedFee}</p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              <p className="tw-text-lg">Required Repayment:</p>
              <p className="tw-text-base tw-font-semibold">{selectedLoan.totalRepay}</p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              <p className="tw-text-lg">Health Factor:</p>
              <p className="tw-text-base tw-font-semibold">{selectedLoan.healthFactor}</p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              
              <LoanHealthGauge value={selectedLoan.healthFactor} />
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              <p className="tw-text-lg">Liquidation Eligible Date:</p>
              <p className="tw-text-base tw-font-semibold">{selectedLoan.liquidationDate}</p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
                    <p className="tw-text-lg">Liquidation Price:</p>
                    <p className="tw-text-base tw-font-semibold">
                      {selectedLoan.liquidationPrice}
                    </p>
                  </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              <p className="tw-text-lg">Liquidator fee in {selectedLoan.collateralName}:</p>
              <p className="tw-text-base tw-font-semibold">{selectedLoan.liquidationFee}</p>
            </div>
            <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
              <p className="tw-text-lg">Liquidation fee in USDS:</p>
              <p className="tw-text-base tw-font-semibold">{selectedLoan.liquidationFee * token.price}</p>
            </div>
          </div>
        );
      case "ManageRepay":
        return (
          <div ref={manageRepayRef} id="ManageRepay" className="tw-flex tw-flex-col tw-items-center tw-p-6 tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-text-white tw-bg-black tw-h-full tw-shadow-lg tw-mt-4">
            <h3 className="tw-text-2xl tw-mb-4 tw-font-poppins tw-text-[#2DB0F3]">
              Deposit Collateral
            </h3>

      {walletConnected && (
        <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
          <p className="tw-text-lg">Available in Wallet:</p>
          <p className="tw-text-base tw-font-semibold">
            {tokens.find(
              (token) => token.name === selectedLoan.collateralName
            )?.quantity || 0}{" "}
            {selectedLoan.collateralName}
          </p>
        </div>
      )}
      <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
        <p className="tw-text-lg">Suggested Deposit:</p>
        <p className="tw-text-base tw-font-semibold">
          {calculateSuggestedCollateralDeposit()}
        </p>
      </div>
      <div className="tw-w-full tw-mb-2 tw-flex tw-justify-center tw-items-center">
        <label className="tw-w-full tw-flex tw-justify tw-text-lg tw-text-[#2DB0F3]">
          <span className="tw-text-lg tw-mr-2 tw-text-white"> Amount:</span>
          <input
            type="number"
            value={collateralDeposit}
            onChange={handleCollateralDepositChange}
            className="tw-w-full tw-p-2 tw-mb-1 tw-border tw-border-[#2DB0F3] tw-rounded tw-text-black tw-bg-white"
            step="0.001"
            min="0"
          />
        </label>
        <p className="tw-text-lg tw-ml-2 tw-text-white">
          {token.name}
        </p>
      </div>
      <button
        onClick={handleDeposit}
        className={`tw-px-4 tw-py-2 tw-mt-4 tw-mb-2 tw-rounded tw-text-white tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb] ${
          collateralDeposit <= 0 ? "tw-opacity-50 tw-cursor-not-allowed" : ""
        }`}
        disabled={collateralDeposit <= 0}
      >
        Deposit Collateral
      </button>
      <div className="tw-w-full tw-mb-0 tw-flex tw-justify-center tw-items-center">
        <h3 className="tw-text-2xl tw-mt-2 tw-mb-4 tw-font-poppins tw-text-[#2DB0F3] tw-flex tw-items-center">
          Withdraw Collateral
          <div className="tw-relative tw-group tw-ml-2">
            <svg
              className="tw-w-5 tw-h-5 tw-text-[#2DB0F3] tw-cursor-pointer"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-8-3a1 1 0 100 2 1 1 0 000-2zm0 4a1 1 0 00-.993.883L9 12v2a1 1 0 001.993.117L11 14v-2a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
            <div className="tw-absolute tw-hidden tw-group-hover:tw-block tw-w-48 tw-bg-black tw-text-white tw-text-sm tw-p-2 tw-rounded tw-shadow-lg tw-z-10 tw-top-full tw-left-1/2 tw-transform tw--translate-x-1/2 tw-mt-2">
              Withdraw excess collateral if required when applicable.
            </div>
          </div>
        </h3>
      </div>
      <div className="tw-w-full tw-mb-0 tw-flex tw-justify-center tw-items-center">
        <label className="tw-w-full tw-flex tw-justify tw-text-lg tw-text-[#2DB0F3]">
          <span className="tw-text-lg tw-mr-2 tw-text-white"> Amount:</span>
          <input
            type="number"
            value={withdrawCollAmount}
            onChange={handleWithdrawAmountChange}
            className="tw-w-full tw-p-2 tw-mb-2 tw-border tw-border-[#2DB0F3] tw-rounded tw-text-black tw-bg-white"
            step="0.001"
            min="0"
            max={selectedLoan.collateralDeposit}
          />
        </label>
        <p className="tw-text-lg tw-ml-2 tw-text-white">
          {selectedLoan.collateralName}
        </p>
      </div>
      <button
        onClick={handleWithdraw}
        className={`tw-px-4 tw-py-2 tw-mb-4 tw-rounded tw-text-white tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb] ${
          withdrawCollAmount <= 0 ? "tw-opacity-50 tw-cursor-not-allowed" : ""
        }`}
        disabled={withdrawCollAmount <= 0}
      >
        Withdraw Collateral
      </button>
      <div className="tw-w-full tw-mt-2 tw-flex tw-justify-center tw-items-center">
        <h3 className="tw-text-2xl tw-mt-2 tw-mb-2 tw-font-poppins tw-text-[#2DB0F3]">
          Repay USDS(DAI)
        </h3>
      </div>
      <div className="tw-w-full tw-mb-2 tw-flex ">
        <label className="tw-w-full tw-flex tw-justify tw-text-lg tw-text-[#2DB0F3]">
          <span className="tw-text-lg tw-mr-2 tw-text-white"> Amount:</span>
          <input
            type="number"
            value={repayAmount}
            onChange={handleRepayAmountChange}
            className=" tw-w-full tw-p-1 tw-mb-0 tw-border tw-border-[#2DB0F3] tw-rounded tw-text-black tw-bg-white tw-mr-2"
            min="0"
            step={0.1}
            max={selectedLoan.totalRepay}
          />
        </label>
        <button
          onClick={handleRepay}
          className={`tw-px-4 tw-py-2 tw-mb-1 tw-rounded tw-text-white tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb] ${
            repayAmount <= 0 ? "tw-opacity-50 tw-cursor-not-allowed" : ""
          }`}
          disabled={repayAmount <= 0}
        >
          Repay
        </button>
      </div>
      <div className="tw-relative tw-w-full tw-mb-4">
                  <input
                    type="range"
                    value={repayAmount}
                    onChange={handleLtvChange}
                    min="0"
                    max={selectedLoan.totalRepay}
                    className="tw-w-full tw-mb-4"
                    style={{ zIndex: 1, position: "relative" }}
                  /> </div>

                  
    </div>
      );
      case "FutureLoanStats":
          return (
            <div ref={futureLoanStatsRef} id="FutureLoanStats" className="tw-flex tw-flex-col tw-items-center tw-p-6 tw-border tw-border-[#2DB0F3] tw-rounded-lg tw-text-white tw-bg-black tw-h-full tw-shadow-lg tw-mt-4">
              <h3 className="tw-text-2xl tw-mb-4 tw-font-poppins tw-text-[#2DB0F3]">Future Loan Stats</h3>
                  <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
                    <p className="tw-text-lg">Collateral Amount:</p>
                    <p className="tw-text-base tw-font-semibold">
                      {futureLoanDetails.collateralDeposit}
                    </p>
                  </div>
                  <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
                    <p className="tw-text-lg">Collateral Value:</p>
                    <p className="tw-text-base tw-font-semibold">
                      { futureLoanDetails.collateralDeposit * token.price}
                    </p>
                  </div>
                  <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
                    <p className="tw-text-lg">Loan Amount:</p>
                    <p className="tw-text-base tw-font-semibold">
                      {futureLoanDetails.borrowedAmount}
                    </p>
                  </div>
                  <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
                    <p className="tw-text-lg">Accumulated Admin Fee:</p>
                    <p className="tw-text-base tw-font-semibold">
                      {selectedLoan.accumulatedFee}
                    </p>
                  </div>
                  <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
                    <p className="tw-text-lg">Required Repayment:</p>
                    <p className="tw-text-base tw-font-semibold">
                      {futureLoanDetails.totalRepay}
                    </p>
                  </div>
                  <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
                    <p className="tw-text-lg">Health Factor:</p>
                    <p className="tw-text-base tw-font-semibold">
                      {futureLoanDetails.healthFactor}
                    </p>
                  </div>
                  <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
                    <LoanHealthGauge value={futureLoanDetails.healthFactor} />
                  </div>
                  <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
                    <p className="tw-text-lg">Liquidation Eligible Date:</p>
                    <p className="tw-text-base tw-font-semibold">
                      {futureLoanDetails.liquidationDate}
                    </p>
                  </div>
                  <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
                    <p className="tw-text-lg">Liquidation Price:</p>
                    <p className="tw-text-base tw-font-semibold">
                      {futureLoanDetails.liquidationPrice}
                    </p>
                  </div>
                  <div className="tw-w-full tw-mb-2 tw-flex tw-justify-between">
                    <p className="tw-text-lg">Liquidator fee in {selectedLoan.collateralName}:</p>
                    <p className="tw-text-base tw-font-semibold">
                      {futureLoanDetails.liquidationFee}
                    </p>
                  </div>
                  <div className="tw-w-full tw-mb-0 tw-flex tw-justify-between">
                    <p className="tw-text-lg">Liquidator fee in USDS:</p>
                    <p className="tw-text-base tw-font-semibold">
                      {futureLoanDetails.liquidationFee * token.price}
                    </p>
                  </div>
                </div>
        );
      default:
        return null;
    }
  };


  return (
    // Manage Loan Component
    <div id="ManageLoan" className="tw-grid tw-items-center main-content tw-relative tw-bg-black">
      <p className="tw-text-white tw-text-center">Loan Contract: <span className="tw-text-blue-500 tw-text-xl">{selectedLoan.contract} </span> User Wallet: <span className="tw-text-blue-500 tw-text-xl">Demo Wallet</span></p>
      <div id="ManageLoan3" className="tw-hidden lg:tw-grid lg:tw-grid-cols-3 gap-4 tw-items-center">
        
        {renderContainer("CurrentLoanStats")}
        {renderContainer("ManageRepay")}
        {renderContainer("FutureLoanStats")}
        
      </div>
      <div className="tw-block lg:tw-hidden">
        {renderContainer(activeContainer)}
      </div>
      {/* Fixed footer */}      
      <div className="fixed-footer lg:tw-hidden">
                    <button
                      onClick={() => setActiveContainer("CurrentLoanStats")}
                      className={`tw-p-2 tw-rounded ${
                        activeContainer === "CurrentLoanStats"
                          ? "tw-bg-green-500"
                          : "tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb]"
                      }`}
                    >
                      <FaChartBar size={24} className="tw-text-white" />
                    </button>
      
                    <button
                      onClick={() => setActiveContainer("ManageRepay")}
                      className={`tw-p-2 tw-rounded ${
                        activeContainer === "ManageRepay"
                          ? "tw-bg-green-500"
                          : "tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb]"
                      }`}
                    >
                      <FaMoneyBillWave size={24} className="tw-text-white" />{" "}
                    </button>
                    <button
                      onClick={() => setActiveContainer("FutureLoanStats")}
                      className={`tw-p-2 tw-rounded ${
                        activeContainer === "FutureLoanStats"
                          ? "tw-bg-green-500"
                          : "tw-bg-[#2DB0F3] hover:tw-bg-[#1a8acb]"
                      }`}
                    >
                      <FaChartLine size={24} className="tw-text-white" />
                    </button>
                  </div>
    </div>
  );
};


export default ManageLoan;